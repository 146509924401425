import { default as _91_46_46_46slug_93VObFUa2DIdMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-cms/pages/[...slug].vue?macro=true";
import { default as indexFA7vPM7zygMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/about-us/index.vue?macro=true";
import { default as addressesEaZ5QtOXNvMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/addresses.vue?macro=true";
import { default as companyPbTyKuUkwqMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/company.vue?macro=true";
import { default as contactHUOURgfAaDMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/contact.vue?macro=true";
import { default as index3Dqdctd6DaMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/index.vue?macro=true";
import { default as listsB232LZUwlkMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/lists.vue?macro=true";
import { default as _91_46_46_46slug_93ilS3NRkr2lMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue?macro=true";
import { default as ordersB0laxUPC0SMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/orders.vue?macro=true";
import { default as payments_45and_45billingcamLGwSHWbMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue?macro=true";
import { default as add_45userRo7gHnUxkMMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue?macro=true";
import { default as editYEXSV6FaQAMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue?macro=true";
import { default as indexhZtFDTXhoWMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue?macro=true";
import { default as newe9INR0l6CJMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue?macro=true";
import { default as editcxsPsb5ZeYMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue?macro=true";
import { default as indexUzGaVCZHBwMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue?macro=true";
import { default as profileremJhkbCc7Meta } from "/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/profile.vue?macro=true";
import { default as accountbUjWwgUIEEMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account.vue?macro=true";
import { default as _91slug_93HitrtqsI55Meta } from "/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue?macro=true";
import { default as categoryLJzT28ORsfMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/category.vue?macro=true";
import { default as cartJddiyPyBh8Meta } from "/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue?macro=true";
import { default as delivery_45and_45paymentTMq5mzuda1Meta } from "/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue?macro=true";
import { default as summaryhHSEbSarFiMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue?macro=true";
import { default as checkoutNUPhHtJJr5Meta } from "/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/checkout.vue?macro=true";
import { default as createPasswordgtRKostpwjMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue?macro=true";
import { default as resetPasswordMy0GR8B3PIMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue?macro=true";
import { default as indexchZidowXG7Meta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/event/index.vue?macro=true";
import { default as indexhzWFdPJLjfMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/newsletter/index.vue?macro=true";
import { default as product6RZUTZ1eRyMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/product.vue?macro=true";
import { default as _91name_93XkYiarL71UMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/reference-objects/[name].vue?macro=true";
import { default as indexe9V8F93qG2Meta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/reference-objects/index.vue?macro=true";
import { default as registerCIkVZr3ShFMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/register.vue?macro=true";
import { default as resolveS1CmI7i60lMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/resolve.vue?macro=true";
import { default as cmsciDQjGnyYIMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/search/cms.vue?macro=true";
import { default as stores6q6DlnyyfMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/search/store.vue?macro=true";
import { default as searchrMo118GgYoMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/search.vue?macro=true";
import { default as DescriptionSectiond9frbZslwGMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue?macro=true";
import { default as HeroSectionXizZxlyKwBMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue?macro=true";
import { default as indexAyqdMybYfiMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue?macro=true";
import { default as ChooseClientdw7PsB4wlaMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue?macro=true";
import { default as ChooseClientElement7GO5RjkRjJMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue?macro=true";
import { default as ClientsElementP03lWDbwbSMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue?macro=true";
import { default as ClientsSectionsXwf9fAwf4Meta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue?macro=true";
import { default as HeroHeader7d0w0uJzSGMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue?macro=true";
import { default as goToJoinRawlplugSectionAHtqpodc4XMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts?macro=true";
import { default as JoinRawlplugwXUiVjyTEhMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue?macro=true";
import { default as JoinRawlplugFormsczAP2uLzpMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue?macro=true";
import { default as JoinRawlplugModal2MILZpf5nYMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue?macro=true";
import { default as JoinRawlplugSticky32szinJTBpMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue?macro=true";
import { default as SlideCtaoPeiyhTpR8Meta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue?macro=true";
import { default as SlideLeftRX3SE5T9MqMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue?macro=true";
import { default as SlideRightpUHgZ6ezNGMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue?macro=true";
import { default as SliderPaginationX3xZqqCZ4hMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue?macro=true";
import { default as SliderSectionOlCgIm6qfVMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue?macro=true";
import { default as slidesfkAWyi8Y2cMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts?macro=true";
import { default as disableAutoScrollOnLastSectionGvpjJCRHJ9Meta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts?macro=true";
import { default as usePaginationWDGvVhqef3Meta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts?macro=true";
import { default as useStyleWithHeaderHeightbeG9RNSuL7Meta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts?macro=true";
import { default as indexmqVQY7zq1RMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/index.vue?macro=true";
import { default as constantscScJyYg5srMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js?macro=true";
import { default as FullPagejUq3HF8XrEMeta } from "/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue?macro=true";
import { default as T3PageD7wsBI8aGsMeta } from "/home/ecomadm/app/releases/185/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.5.12/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93VObFUa2DIdMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-cms/pages/[...slug].vue")
  },
  {
    name: "locale-about-us",
    path: "/:locale()/about-us",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/about-us/index.vue")
  },
  {
    name: "account",
    path: "/:locale()/account",
    meta: accountbUjWwgUIEEMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account.vue"),
    children: [
  {
    name: addressesEaZ5QtOXNvMeta?.name ?? "locale-account-addresses",
    path: "addresses",
    meta: addressesEaZ5QtOXNvMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/addresses.vue")
  },
  {
    name: companyPbTyKuUkwqMeta?.name ?? "locale-account-company",
    path: "company",
    meta: companyPbTyKuUkwqMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/company.vue")
  },
  {
    name: contactHUOURgfAaDMeta?.name ?? "locale-account-contact",
    path: "contact",
    meta: contactHUOURgfAaDMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/contact.vue")
  },
  {
    name: "locale-account",
    path: "",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/index.vue")
  },
  {
    name: listsB232LZUwlkMeta?.name ?? "locale-account-lists",
    path: "lists",
    meta: listsB232LZUwlkMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/lists.vue")
  },
  {
    name: "locale-account-order-slug",
    path: "order/:slug+",
    meta: _91_46_46_46slug_93ilS3NRkr2lMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue")
  },
  {
    name: ordersB0laxUPC0SMeta?.name ?? "locale-account-orders",
    path: "orders",
    meta: ordersB0laxUPC0SMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/orders.vue")
  },
  {
    name: payments_45and_45billingcamLGwSHWbMeta?.name ?? "locale-account-payments-and-billing",
    path: "payments-and-billing",
    meta: payments_45and_45billingcamLGwSHWbMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue")
  },
  {
    name: "locale-account-permissions-add-user",
    path: "permissions/add-user",
    meta: add_45userRo7gHnUxkMMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue")
  },
  {
    name: "locale-account-permissions-roles-id-edit",
    path: "permissions/roles/:id()/edit",
    meta: editYEXSV6FaQAMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue")
  },
  {
    name: "locale-account-permissions-roles",
    path: "permissions/roles",
    meta: indexhZtFDTXhoWMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue")
  },
  {
    name: "locale-account-permissions-roles-new",
    path: "permissions/roles/new",
    meta: newe9INR0l6CJMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue")
  },
  {
    name: "locale-account-permissions-users-id-edit",
    path: "permissions/users/:id()/edit",
    meta: editcxsPsb5ZeYMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue")
  },
  {
    name: indexUzGaVCZHBwMeta?.name ?? "locale-account-permissions-users",
    path: "permissions/users",
    meta: indexUzGaVCZHBwMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue")
  },
  {
    name: profileremJhkbCc7Meta?.name ?? "locale-account-profile",
    path: "profile",
    meta: profileremJhkbCc7Meta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/account/profile.vue")
  }
]
  },
  {
    name: "category",
    path: "/:path/c/",
    meta: categoryLJzT28ORsfMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/category.vue"),
    children: [
  {
    name: "category",
    path: ":slug+",
    meta: _91slug_93HitrtqsI55Meta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue")
  }
]
  },
  {
    name: checkoutNUPhHtJJr5Meta?.name ?? "locale-checkout",
    path: "/:path/checkout/",
    meta: checkoutNUPhHtJJr5Meta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/checkout.vue"),
    children: [
  {
    name: cartJddiyPyBh8Meta?.name ?? "locale-checkout-cart",
    path: "/:path/checkout/cart",
    meta: cartJddiyPyBh8Meta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue")
  },
  {
    name: "checkout-delivery-and-payment",
    path: "/:path/checkout/delivery-and-payment",
    meta: delivery_45and_45paymentTMq5mzuda1Meta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue")
  },
  {
    name: "checkout-summary",
    path: "/:path/checkout/summary",
    meta: summaryhHSEbSarFiMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue")
  }
]
  },
  {
    name: "locale-customer-account-createPassword",
    path: "/:locale()/customer/account/createPassword",
    meta: createPasswordgtRKostpwjMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue")
  },
  {
    name: "locale-customer-account-resetPassword",
    path: "/:locale()/customer/account/resetPassword",
    meta: resetPasswordMy0GR8B3PIMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue")
  },
  {
    name: "locale-event",
    path: "/:locale()/event",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/event/index.vue")
  },
  {
    name: "locale-newsletter",
    path: "/:locale()/newsletter",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/newsletter/index.vue")
  },
  {
    name: "product",
    path: "/:path/p/:slug",
    meta: product6RZUTZ1eRyMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/product.vue")
  },
  {
    name: "locale-reference-objects-name",
    path: "/:locale()/reference-objects/:name()",
    meta: _91name_93XkYiarL71UMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/reference-objects/[name].vue")
  },
  {
    name: "locale-reference-objects",
    path: "/:locale()/reference-objects",
    meta: indexe9V8F93qG2Meta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/reference-objects/index.vue")
  },
  {
    name: registerCIkVZr3ShFMeta?.name ?? "locale-register",
    path: "/:locale()/register",
    meta: registerCIkVZr3ShFMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-customer/pages/[locale]/register.vue")
  },
  {
    name: "ecom-redirect-resolve",
    path: "/:locale/r/:type/:slug",
    meta: resolveS1CmI7i60lMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/resolve.vue")
  },
  {
    name: searchrMo118GgYoMeta?.name ?? "locale-search",
    path: "/:path/search",
    meta: searchrMo118GgYoMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/search.vue"),
    children: [
  {
    name: cmsciDQjGnyYIMeta?.name ?? "locale-search-cms",
    path: "/:path/search/cms",
    meta: cmsciDQjGnyYIMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/search/cms.vue")
  },
  {
    name: stores6q6DlnyyfMeta?.name ?? "locale-search-store",
    path: "/:path/search/store",
    meta: stores6q6DlnyyfMeta || {},
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-ecom/pages/[locale]/search/store.vue")
  }
]
  },
  {
    name: "locale-technical-helpdesk-components-DescriptionSection",
    path: "/:locale()/technical-helpdesk/components/DescriptionSection",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue")
  },
  {
    name: "locale-technical-helpdesk-components-HeroSection",
    path: "/:locale()/technical-helpdesk/components/HeroSection",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue")
  },
  {
    name: "locale-technical-helpdesk",
    path: "/:locale()/technical-helpdesk",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClient",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClient",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClientElement-ChooseClientElement",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsElement-ClientsElement",
    path: "/:locale()/welcome/components/ClientsSection/ClientsElement/ClientsElement",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsSection",
    path: "/:locale()/welcome/components/ClientsSection/ClientsSection",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue")
  },
  {
    name: "locale-welcome-components-HeroHeader-HeroHeader",
    path: "/:locale()/welcome/components/HeroHeader/HeroHeader",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-goToJoinRawlplugSection",
    path: "/:locale()/welcome/components/JoinRawlplug/goToJoinRawlplugSection",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplug",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplug",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugForm",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugForm",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugModal",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugModal",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugSticky",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugSticky",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideCta",
    path: "/:locale()/welcome/components/slider/SlideCta",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideLeft",
    path: "/:locale()/welcome/components/slider/SlideLeft",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideRight",
    path: "/:locale()/welcome/components/slider/SlideRight",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue")
  },
  {
    name: "locale-welcome-components-slider-SliderPagination",
    path: "/:locale()/welcome/components/slider/SliderPagination",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue")
  },
  {
    name: "locale-welcome-components-slider-SliderSection",
    path: "/:locale()/welcome/components/slider/SliderSection",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue")
  },
  {
    name: "locale-welcome-components-slider-slides",
    path: "/:locale()/welcome/components/slider/slides",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts")
  },
  {
    name: "locale-welcome-composables-disableAutoScrollOnLastSection",
    path: "/:locale()/welcome/composables/disableAutoScrollOnLastSection",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts")
  },
  {
    name: "locale-welcome-composables-usePagination",
    path: "/:locale()/welcome/composables/usePagination",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts")
  },
  {
    name: "locale-welcome-composables-useStyleWithHeaderHeight",
    path: "/:locale()/welcome/composables/useStyleWithHeaderHeight",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts")
  },
  {
    name: "locale-welcome",
    path: "/:locale()/welcome",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/index.vue")
  },
  {
    name: "locale-welcome-vendors-fullpage-constants",
    path: "/:locale()/welcome/vendors/fullpage/constants",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js")
  },
  {
    name: "locale-welcome-vendors-fullpage-FullPage",
    path: "/:locale()/welcome/vendors/fullpage/FullPage",
    component: () => import("/home/ecomadm/app/releases/185/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue")
  },
  {
    name: "page",
    path: "/:slug(.*)*",
    component: () => import("/home/ecomadm/app/releases/185/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.5.12/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue")
  }
]